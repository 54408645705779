<template>
<div class="d-flex align-items-end flex-column h-100">
  <form class="w-100" @submit.stop.prevent="onSubmit">
    <b-form-group id="input-group-datepicker-from" label-align-md="left" :label="$t('ORDER.START_DATE')" label-size="sm" label-class="text-muted">
      <Datepicker size="sm" reset-button
                  v-model="params.period.from"
      />
    </b-form-group>
    <b-form-group id="input-group-datepicker-to" label-align-md="left" label-size="sm" label-class="text-muted">
      <Datepicker size="sm" reset-button
                  v-model="params.period.to"
      />
    </b-form-group>
    <b-form-group id="input-group-datepicker-from" label-align-md="left" :label="$t('ORDERS.DEADLINE')" label-size="sm" label-class="text-muted">
      <Datepicker size="sm" reset-button
                  v-model="params.deadlineFrom"
      />
    </b-form-group>
    <b-form-group id="input-group-datepicker-to" label-align-md="left" label-size="sm" label-class="text-muted">
      <Datepicker size="sm" reset-button
                  v-model="params.deadlineTo"
      />
    </b-form-group>
    <b-form-group id="input-group-clients" label-align-md="left" :label="$t('FORM.CLIENTS')" label-size="sm" label-class="text-muted">
      <SelectInfinityScroll
        v-model="params.clients"
        :search.sync="clientSearch"
        :params="paramsBranch"
        clearable
        multiple
        text-field="firstName"
        :placeholder="$t('REPORTS.FILTERS.CLIENTS')"
        api="company/client"
        aria-describedby="clients-feedback"
      >
        <template slot="option" slot-scope="{ option }">
          {{ option.firstName }} {{ option.lastName }} (Phone: {{ option.phone }})
        </template>
      </SelectInfinityScroll>
    </b-form-group>
    <b-form-group
      v-if="orderStatuses.length"
      :label="$t('REPORTS.FILTERS.ORDER_STATUS')"
      id="input-group-order-statuses"
      label-align-md="left"
      label-size="sm"
      label-class="text-muted"
    >
      <SelectInfinityScroll
        v-model="params.orderStatuses"
        clearable
        multiple
        :items="orderStatuses"
        :placeholder="$t('REPORTS.FILTERS.ORDER_STATUS')"
        aria-describedby="order-statuses"
      />
    </b-form-group>
    <b-form-group
      v-if="paymentStatuses.length"
      :label="$t('REPORTS.FILTERS.PAYMENT_STATUS')"
      id="input-group-payment-statuses"
      label-align-md="left"
      label-size="sm" label-class="text-muted"
    >
      <SelectInfinityScroll
        v-model="params.paymentStatuses"
        clearable
        multiple
        :items="paymentStatuses"
        :placeholder="$t('REPORTS.FILTERS.PAYMENT_STATUS')"
        aria-describedby="payment-statuses"
      />
    </b-form-group>
    <b-form-group id="input-group-creators" label-align-md="left" :label="$t('FORM.CREATORS')" label-size="sm" label-class="text-muted">
      <SelectInfinityScroll
        v-model="params.creators"
        :search.sync="creatorSearch"
        :params="paramsCreator"
        clearable
        text-field="firstName"
        multiple
        :placeholder="$t('REPORTS.FILTERS.CREATORS')"
        api="company/business-users"
        aria-describedby="creators"
      >
        <template slot="option" slot-scope="{ option }">
          {{ option.firstName }} {{ option.lastName }}
        </template>
      </SelectInfinityScroll>
    </b-form-group>
  </form>
  <div class="mt-auto pb-3 pb-sm-0 w-100">
    <b-button class="w-100 d-flex align-items-center justify-content-center btn btn-light-success font-weight-bold btn-sm" @click="onSubmit">
      <span class="svg-icon btn-light-success">
        <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
      </span>
      {{ $t('FORM.SUBMIT') }}
    </b-button>
    <div class="fake-element">
      <!--      IOS fix-->
    </div>
  </div>
</div>
</template>

<script>
import { DATE_START, DATE_END } from '@/helpers/validation'
import Datepicker from '../forms-items/Datepicker'
import { STATUSES } from '../../constants/statuses-order'
import { STATUSES as PAYMENT_STATUSES } from '../../constants/statuses-payment'
import SelectInfinityScroll from '../forms-items/SelectInfinityScroll'

export default {
  name: 'FilterOrder',
  components: {
    SelectInfinityScroll,
    Datepicker,
  },
  data() {
    return {
      DATE_START,
      DATE_END,
      orderStatuses: [],
      paymentStatuses: [],
      clientSearch: '',
      creatorSearch: '',
      params: {
        period: {
          from: null,
          to: null,
        },
        deadlineFrom: null,
        deadlineTo: null,
        clients: [],
        creators: [],
        orderStatuses: [],
        paymentStatuses: [],
      },
    }
  },
  computed: {
    paramsBranch() {
      return {
        search: this.clientSearch,
      }
    },
    paramsCreator() {
      return {
        search: this.creatorSearch,
      }
    },
  },
  mounted() {
    this.orderStatuses = STATUSES.map((s) => ({
      uuid: s.val,
      name: this.$t(`ORDER.STATUSES.${s.val}`),
    }))
    this.paymentStatuses = PAYMENT_STATUSES.map((s) => ({
      uuid: s,
      name: this.$t(`ORDER.PAYMENT_STATUSES.${s}`),
    }))
  },
  methods: {
    onSubmit() {
      if (this.params.period.from) this.params.period.from = this.$moment(this.params.period.from).startOf('day').format(DATE_START)
      if (this.params.deadlineFrom) this.params.deadlineFrom = this.$moment(this.params.deadlineFrom).startOf('day').format(DATE_START)
      if (this.params.period.to) this.params.period.to = this.$moment(this.params.period.to).endOf('day').format(DATE_END)
      if (this.params.deadlineTo) this.params.deadlineTo = this.$moment(this.params.deadlineTo).endOf('day').format(DATE_END)
      this.$emit('onSubmit', JSON.parse(JSON.stringify(this.params)))
    },
  },
}
</script>

<style scoped>

</style>
