<template>
<KTCard>
  <template #body>
    <div class="d-flex justify-content-between align-items-start">
      <div class="d-flex flex-column">
        <span class="text-dark font-weight-bolder font-size-h4 mb-0">
          {{ item.num }}
        </span>
        <span class="text-muted font-weight-bold">
          {{ item.createdBy.firstName }} {{ item.createdBy.lastName }}
        </span>
      </div>
      <b-dropdown
        size="lg"
        variant="link"
        toggle-class="text-decoration-none p-0"
        no-caret
        right
        no-flip
      >
        <template #button-content>
          <div class="label label-xl label-inline pr-0" :class="`label-${getColorStatus}`">
            {{ $t(`ORDER.STATUSES.${item.status}`) || $t('BASE.NOT_SELECTED') }}
            <div class="btn btn-icon btn-sm btn-dropdown p-0">
              <span class="svg-icon svg-icon-sm svg-icon-light">
                <inline-svg src="/media/svg/icons/Navigation/Angle-down.svg" />
              </span>
            </div>
          </div>
        </template>
        <b-dropdown-item
          v-for="status in statuses"
          :key="status.val"
          :active="status.val === item.status"
          active-class="info"
          @click="changeStatus(status.val)"
        >
          {{ $t(`ORDER.STATUSES.${status.val}`) }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="mb-7 mt-3">
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-dark-75 font-weight-bolder mr-2">{{ $t('ORDERS.CLIENT_NAME') }}:</span>
        <a href="#" class="text-muted text-hover-primary">{{ item.client.firstName }} {{ item.client.lastName }}</a>
      </div>
      <div class="d-flex justify-content-between align-items-cente my-1">
        <span class="text-dark-75 font-weight-bolder mr-2">{{ $t('ORDERS.PHONE') }}:</span>
        <a href="#" class="text-muted text-hover-primary">{{ item.client.phone }}</a>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-dark-75 font-weight-bolder mr-2">{{ $t('ORDERS.TOTAL_COST') }}:</span>
        <span class="text-muted font-weight-bold">{{ item.costWithoutDiscount | centsToDollars }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-dark-75 font-weight-bolder mr-2">{{ $t('ORDERS.FINAL_COST') }}:</span>
        <span class="text-muted font-weight-bold">{{ item.costWithDiscount | centsToDollars }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-dark-75 font-weight-bolder mr-2">{{ $t('ORDERS.PREPAYMENT') }}:</span>
        <span class="font-weight-bold">
          <span v-if="item.hasOwnProperty('prepayment') && item.hasOwnProperty('costWithDiscount')">
            <span v-if="item.paymentTotal === item.costWithDiscount" class="text-success">{{ item.paymentTotal | centsToDollars }}</span>
            <span
              v-else-if="item.paymentTotal < item.costWithDiscount && item.paymentTotal"
              class="text-warning"
            >
              {{ item.paymentTotal | centsToDollars }}
            </span>
            <span v-else class="text-danger">{{ item.paymentTotal | centsToDollars }}</span>
          </span>
        </span>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-dark-75 font-weight-bolder mr-2">{{ $t('ORDER.START_DATE') }}:</span>
        <span class="text-muted font-weight-bold">{{ item.createdAt ? $moment(item.createdAt).format('DD MMM, yyyy HH:mm') : '-' }}</span>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <span class="text-dark-75 font-weight-bolder mr-2">{{ $t('ORDER.DUE_DATE') }}:</span>
        <span class="text-muted font-weight-bold">{{ item.deadline ? $moment(item.deadline).format('DD MMM, yyyy') : '-' }}</span>
      </div>
    </div>
    <router-link
      v-permission="['edit_orders']"
      :to="{ name: 'companyOrdersEdit', params: { id: item.uuid }, query: { client: item.client.uuid, order: item.uuid, type: '' }}"
      class="btn btn-block btn-light-info btn-sm"
    >
      <span class="svg-icon btn-light-primary">
        <inline-svg src="/media/svg/icons/Communication/Write.svg" />
      </span>
      {{ $t('BASE.EDIT') }}
    </router-link>
    <div
      v-permission="['edit_orders']"
      class="btn btn-block btn-light-danger btn-sm mt-3"
      @click="$emit('onDelete', item)"
    >
      <span class="svg-icon svg-icon-md btn-light-info">
        <inline-svg src="/media/svg/icons/Home/Trash.svg" />
      </span>
      {{ $t('BASE.DELETE') }}
    </div>
  </template>
</KTCard>
</template>

<script>
import Repo from '@/core/repository/company/ordersRepository'
import { STATUSES } from '../../../constants/statuses-order'
import KTCard from '../../../view/content/Card'

export default {
  name: 'OrderCardItem',
  components: { KTCard },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statuses: STATUSES,
    }
  },
  computed: {
    getColorStatus() {
      const obj = this.statuses.find((f) => f.val === this.item.status)
      if (obj) return obj.variant
      return 'light'
    },
  },
  methods: {
    changeStatus(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.item.status = val // TODO: ???
      Repo.patch(this.item.uuid, { status: this.item.status })
    },
  },
}
</script>

<style scoped>

</style>
