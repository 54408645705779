<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <!--begin::Title-->
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 d-none d-sm-block">{{ $t('ORDERS.TITLE') }}</h5>
      <!--end::Title-->
      <!--begin::Separator-->
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200 d-none d-sm-block" />
      <!--end::Separator-->
      <!--begin::Search Form-->
      <div class="d-flex align-items-center" id="kt_subheader_search">
        <span v-if="totalCount" class="text-dark-50 font-weight-bold mr-5" id="kt_subheader_total">{{ totalCount }} {{ $t('BASE.TOTAL') }}</span>
        <form @submit.stop.prevent="">
          <b-input-group class="input-group input-group-sm input-group-solid" style="max-width: 175px">
            <InputForm
              v-model="search"
              id="kt_subheader_search_form"
              trim
              type="text"
              debounce="500"
              :placeholder="$t('BASE.SEARCH')"
            />
            <b-input-group-append>
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </b-input-group-append>
          </b-input-group>
        </form>
      </div>
      <!--end::Search Form-->
    </div>
  </template>
  <template #toolbar>
    <div v-permission="['edit_orders']" class="example-tools justify-content-center">
      <router-link :to="{ name: 'companyOrdersCreate' }" class="btn btn-light-success font-weight-bold btn-sm">
        <span class="svg-icon btn-light-success">
          <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
        </span>
        {{ $t('ORDERS.NEW') }}
      </router-link>
    </div>
    <div class="btn btn-light-primary btn-hover-primary btn-sm ml-3" @click="$emit('onFilter')">
      <span class="svg-icon svg-icon-primary cursor-pointer mr-0">
        <inline-svg src="/media/svg/icons/General/Settings-1.svg" />
      </span>
    </div>
  </template>
  <template #body>
    <Table :fields="fields" :items="items" :busy.sync="loading">
      <template #cell(number)="data">
        <div style="min-width: 135px">
          <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.num }}</span>
        </div>
      </template>
      <template #cell(client)="data">
        <div style="min-width: 150px">
          <span class="text-muted font-weight-bolder font-size-lg mb-0">{{ data.item.client.firstName }} {{ data.item.client.lastName }}</span>
        </div>
      </template>
      <template #cell(totalCost)="data">
        <div style="min-width: 135px">
          <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">{{ data.item.costWithoutDiscount | centsToDollars }}</span>
        </div>
      </template>
      <template #cell(status)="data">
        <div style="min-width: 80px">
          <div
            :class="`label-${getColorStatus(data.item.status)}`"
            class="label label-md label-inline"
          >
            {{ $t(`ORDER.STATUSES.${data.item.status}`) || $t('BASE.NOT_SELECTED') }}
          </div>
        </div>
      </template>
      <template #cell(finalCost)="data">
        <div style="min-width: 135px">
          <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">
            {{ data.item.costWithDiscount | centsToDollars }}
          </span>
        </div>
      </template>
      <template #cell(prepayment)="data">
        <div
          v-if="data.item.hasOwnProperty('prepayment') && data.item.hasOwnProperty('costWithDiscount')"
          style="min-width: 150px"
          class="font-weight-bolder font-size-lg"
        >
          <span
            v-if="data.item.paymentTotal === data.item.costWithDiscount"
            class="text-success"
          >
            {{ data.item.paymentTotal | centsToDollars }}
          </span>
          <span
            v-else-if="data.item.paymentTotal < data.item.costWithDiscount && data.item.paymentTotal"
            class="text-warning"
          >
            {{ data.item.paymentTotal | centsToDollars }}
          </span>
          <span
            v-else
            class="text-danger"
          >
            {{ data.item.paymentTotal | centsToDollars }}
          </span>
        </div>
      </template>
      <template #cell(actions)="data">
        <div style="min-width: 80px">
          <show-qr-code-button classes="mr-3" :has-active-bot="hasActiveBot" :client-uuid="data.item.client.uuid" />
          <router-link
            v-permission="['edit_orders']"
            :to="{
              name: 'companyOrdersEdit',
              params: { id: data.item.uuid },
              query: { client: data.item.client.uuid, order: data.item.uuid, type: '' }
            }"
            class="btn btn-icon btn-light-info btn-sm mr-3"
          >
            <span class="svg-icon btn-light-primary">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </router-link>
          <div v-permission="['edit_orders']" class="btn btn-icon btn-light-danger btn-sm" @click="onDelete(data.item)">
            <span class="svg-icon svg-icon-md btn-light-info">
              <inline-svg src="/media/svg/icons/Home/Trash.svg" />
            </span>
          </div>
        </div>
      </template>
    </Table>
    <div v-if="totalCount && params.pagination.limit < totalCount" class="mt-3">
      <Paginations v-model="params.pagination.page"
                   :total-rows="totalCount"
                   :per-page="params.pagination.limit"
      />
    </div>
  </template>
</KTCard>
</template>

<script>
import Repo from '@/core/repository/company/ordersRepository'
import ShowQrCodeButton from '@/components/bot/ShowQrCodeButton'
import { STATUSES } from '@/constants/statuses-order'
import SettingsRepo from '@/core/repository/company/settingsRepository'
import InputForm from '../../forms-items/input'
import Paginations from '../../UI/Paginations'
import Table from '../../UI/Table'
import baseList from '../../../mixins/baseList'
import KTCard from '../../../view/content/Card'

export default {
  name: 'OrderTableView',
  components: {
    KTCard,
    Table,
    Paginations,
    InputForm,
    ShowQrCodeButton,
  },
  mixins: [baseList],
  props: {
    value: null,
  },
  data() {
    return {
      hasActiveBot: false,
      statuses: STATUSES,
      fields: [
        {
          key: 'number',
          label: this.$t('ORDERS.NUMBER'),
          sortable: false,
        },
        {
          key: 'client',
          label: this.$t('ORDERS.CLIENT_NAME'),
          sortable: false,
        },
        {
          key: 'totalCost',
          label: this.$t('ORDERS.TOTAL_COST'),
          sortable: false,
        },
        {
          key: 'finalCost',
          label: this.$t('ORDERS.FINAL_COST'),
          sortable: false,
        },
        {
          key: 'prepayment',
          label: this.$t('ORDERS.PREPAYMENT'),
          sortable: false,
        },
        {
          key: 'status',
          label: this.$t('ORDERS.STATUS'),
          sortable: false,
        },
        {
          key: 'actions',
          label: this.$t('BASE.ACTIONS'),
          class: 'text-right',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    getColorStatus() {
      return (val) => {
        const obj = this.statuses.find((f) => f.val === val)
        if (obj) return obj.variant
        return 'light'
      }
    },
    Repo() {
      return Repo
    },
  },
  watch: {
    value: {
      handler() {
        this.reFetchData()
      },
      deep: true,
    },
    search(v) {
      this.params.search = v
      if (!v) delete this.params.search
      this.reFetchData()
    },
  },
  mounted() {
    SettingsRepo.get()
      .then((settings) => {
        if (settings.activeTelegramBot) {
          this.hasActiveBot = true
        }
      })
      .catch(() => {})
  },
  methods: {
    fetchData() {
      this.loading = true
      const params = {
        ...this.params,
        ...this.value,
      }
      if (params.clients && params.clients.length) params.clients = params.clients.map((item) => item.uuid)
      if (params.creators && params.creators.length) params.creators = params.creators.map((item) => item.uuid)
      if (params.orderStatuses && params.orderStatuses.length) params.orderStatuses = params.orderStatuses.map((item) => item.uuid)
      if (params.paymentStatuses && params.paymentStatuses.length) params.paymentStatuses = params.paymentStatuses.map((item) => item.uuid)
      this.Repo.list(params).then(({ data }) => {
        this.items = data.payload.items.map((order) => ({
          ...order,
          paymentTotal: order.payments.reduce((acc, current) => acc + current.sum, 0),
        }))
        this.totalCount = data.payload.pagination.totalCount
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
