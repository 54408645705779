<template>
<div>
  <div class="subheader subheader-solid mt-n5 mx-n3 mx-md-n5 px-5 py-5 d-flex justify-content-between bg-white">
    <div class="d-flex align-items-center flex-wrap mr-2">
      <!--begin::Title-->
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 d-none d-sm-block">{{ $t('ORDERS.TITLE') }}</h5>
      <!--end::Title-->
      <!--begin::Separator-->
      <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200 d-none d-sm-block" />
      <!--end::Separator-->
      <!--begin::Search Form-->
      <div class="d-flex align-items-center" id="kt_subheader_search">
        <span v-if="totalCount" class="text-dark-50 font-weight-bold mr-5" id="kt_subheader_total">{{ totalCount }} {{ $t('BASE.TOTAL') }}</span>
        <form @submit.stop.prevent="">
          <b-input-group class="input-group input-group-sm input-group-solid" style="max-width: 175px">
            <InputForm
              v-model="search"
              id="kt_subheader_search_form"
              trim
              type="text"
              debounce="500"
              :placeholder="$t('BASE.SEARCH')"
            />
            <b-input-group-append>
              <span class="input-group-text">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/General/Search.svg" />
                </span>
              </span>
            </b-input-group-append>
          </b-input-group>
        </form>
      </div>
      <!--end::Search Form-->
    </div>
    <div class="d-flex align-items-center flex-wrap flex-shrink-0">
      <div v-permission="['edit_orders']" class="example-tools justify-content-center">
        <router-link :to="{ name: 'companyOrdersCreate' }" class="btn btn-light-success font-weight-bold btn-sm">
          <span class="svg-icon btn-light-success">
            <inline-svg src="/media/svg/icons/Files/File-plus.svg" />
          </span>
          {{ $t('ORDERS.NEW') }}
        </router-link>
      </div>
      <div class="btn btn-light-primary btn-hover-primary btn-sm ml-3" @click="$emit('onFilter')">
        <span class="svg-icon svg-icon-primary cursor-pointer mr-0">
          <inline-svg src="/media/svg/icons/General/Settings-1.svg" />
        </span>
      </div>
    </div>
  </div>
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="12" md="6" v-for="item in items" :key="item.uuid">
        <orderCardItem :item="item" @onDelete="onDelete" />
      </b-col>
    </b-row>
  </b-container>
  <infinite-loading ref="infiniteLoading"
                    :distance="300"
                    :identifier="infiniteId"
                    @infinite="fetchData"
  >
    <div slot="spinner">
      <div class="text-center">
        <b-spinner variant="secondary" class="align-middle mr-2" />
      </div>
    </div>
    <div slot="no-more" />
    <div slot="no-results">
      <div class="text-center">
        {{ $t('BASE.EMPTY_DATA') }}
      </div>
    </div>
  </infinite-loading>
</div>
</template>

<script>
import ModalConfirm from '@/components/modals/ModalConfirm'
import Repo from '@/core/repository/company/ordersRepository'
import InputForm from '../../forms-items/input'
import { STATUSES } from '../../../constants/statuses-order'
import OrderCardItem from './orderCardItem'

export default {
  name: 'OrderCardsView',
  components: {
    OrderCardItem,
    InputForm,
  },
  props: {
    value: null,
  },
  data() {
    return {
      statuses: STATUSES,
      infiniteId: +new Date(),
      search: '',
      params: {
        pagination: {
          page: 1,
          limit: 10,
        },
        order: {
          createdAt: 'desc',
        },
      },
      totalCount: null,
      items: [],
      loading: false,
    }
  },
  computed: {
    getColorStatus() {
      return (val) => {
        const obj = this.statuses.find((f) => f.val === val)
        if (obj) return obj.variant
        return 'light'
      }
    },
    Repo() {
      return Repo
    },
  },
  watch: {
    value: {
      handler() {
        this.reFetchData()
      },
      deep: true,
    },
    search(v) {
      this.params.search = v
      if (!v) delete this.params.search
      this.reFetchData()
    },
  },
  created() {},
  methods: {
    onDelete(item) {
      this.$modal.show(ModalConfirm, {
        handlers: {
          onConfirm: () => {
            this.Repo.delete(item.uuid).then(() => {
              this.items = this.items.filter((el) => el.uuid !== item.uuid)
            })
          },
        },
      }, {
        height: 'auto',
        adaptive: true,
        clickToClose: false,
      })
    },
    reFetchData() {
      this.items = []
      this.params.pagination.page = 1
      this.infiniteId += 1
      this.fetchData()
    },
    fetchData() {
      if (this.loading) return
      this.loading = true
      const params = {
        ...this.params,
        ...this.value,
      }
      if (params.clients && params.clients.length) params.clients = params.clients.map((item) => item.uuid)
      if (params.orderStatuses && params.orderStatuses.length) params.orderStatuses = params.orderStatuses.map((item) => item.uuid)
      if (params.paymentStatuses && params.paymentStatuses.length) params.paymentStatuses = params.paymentStatuses.map((item) => item.uuid)
      const state = this.$refs.infiniteLoading
      this.Repo.list(params).then(({ data }) => {
        if (data.payload.items.length) {
          this.totalCount = data.payload.pagination.totalCount
          this.params.pagination.page += 1
          this.items = this.items.concat(data.payload.items).map((item) => ({
            ...item,
            paymentTotal: item.payments.reduce((acc, current) => acc + current.sum, 0),
          }))
          state.stateChanger.loaded()
        } else {
          state.stateChanger.complete()
        }
        if (data.payload.items.length < this.params.pagination.limit) state.stateChanger.complete()
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
