const STATUSES = [
  {
    val: 'raw',
    variant: 'primary', 
  },
  {
    val: 'in_progress',
    variant: 'warning', 
  },
  {
    val: 'cancel',
    variant: 'danger', 
  },
  {
    val: 'complete',
    variant: 'success', 
  },
  {
    val: 'archived',
    variant: 'secondary', 
  },
]

export { STATUSES }
