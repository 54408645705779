<template>
<div>
  <orderCardsView v-if="$store.getters.isMobile" v-model="params" @onFilter="isFilter = true" />
  <orderTableView v-else v-model="params" @onFilter="isFilter = true" />
  <quick-panel v-model="isFilter">
    <template #title>{{ $t('BASE.CRITERIA') }}</template>
    <template #body>
      <FilterOrder @onSubmit="onSubmit" />
    </template>
  </quick-panel>
</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import OrderTableView from '../../../../components/order/view/orderTableView'
import OrderCardsView from '../../../../components/order/view/orderCardsView'
import FilterOrder from '../../../../components/order/FilterOrder'

export default {
  name: 'CompanyOrdersIndex',
  components: {
    FilterOrder,
    OrderCardsView,
    OrderTableView, 
  },
  data() {
    return {
      isFilter: false,
      params: {},
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PAGE.ORDERS') }])
  },
  methods: {
    onSubmit(val) {
      this.params = val
      this.isFilter = false
    },
  },
}
</script>

<style scoped>

</style>
